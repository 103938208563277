import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react"
import { Tooltip } from "@chakra-ui/react"

import { motion } from "framer-motion"
import React from "react"

const MeterBar: React.FC<{
  label: string
  value: number
  max: number
  icon: React.ReactNode
  modifier: number
}> = ({ value, max, icon, modifier, label }) => {
  // Calculate the total percentage considering value and modifier
  const percentage = Math.min((value / max) * 100, 100) // Ensure it does not exceed 100%
  const minValue =  Math.min(value, max) // Ensure it does not exceed max

  // Determine color based on modifier
  const color = modifier !== 0 ? "#FF5733" : "#4ca64c" // Use a different color when modifier is not zero

  const Wrapper = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(({ children, ...rest }, ref) => (
    <div ref={ref} {...rest}>
      {children}
    </div>
  ))

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <motion.div
        style={{
          width: "100%",
          borderRadius: "5px",
        }}
        animate={{ color: color }}
        initial={{ color: "#4ca64c" }}
        transition={{
          color: { duration: 0.33, ease: "easeInOut" },
        }}
      >
        <CircularProgress
          value={percentage}
          color={color}
          thickness="10px"
          size="100px"
          trackColor="#d3d3d3"
          capIsRound
        >
          <CircularProgressLabel>
            <Tooltip label={label+ " ("+ minValue+")"} aria-label={"Progress Bar for " + label}>
              <Wrapper>{icon}</Wrapper>
            </Tooltip>
          </CircularProgressLabel>
        </CircularProgress>
      </motion.div>
    </div>
  )
}

export default MeterBar

import { FaPeopleArrows } from "react-icons/fa"
import { useAppSelector } from "../../app/hooks"
import MeterBar from "../../components/MeterBar"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { selectGameRelationship } from "./gameSlice"
import { Icon } from "@chakra-ui/react"

export const RelationshipMeter = () => {
  const turnRelationship = useAppSelector(selectGameRelationship)
  const { relationshipModifier } = useGameTriggerContext()
  return (
    <MeterBar
      label="Relationship"
      value={turnRelationship}
      max={25}
      icon={<Icon mt={2} boxSize={8} as={FaPeopleArrows} />}
      modifier={relationshipModifier}
    />
  )
}

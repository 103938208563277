import { motion, AnimatePresence } from "motion/react"
import {
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  MenuItem,
  Box,
} from "@chakra-ui/react"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  AvatarOptionsData,
  selectCharacterAvatar,
  updateCharacterAvatar,
} from "./characterSlice"
import React from "react"
import { EditedAvatarProvider, useEditedAvatar } from "./useEditedCharacter"
import { useAvatarEditorModal } from "./useAvatarEditorModal"
import { GameLogicCharacter } from "@types"

const labelTitles: GameLogicCharacter["avatar"] = {
  hairLayer: "Hair",
  eyebrowsLayer: "Brows",
  glassesLayer: "Glasses",
  faceLayer: "Face",
  chestLayer: "Outfit",
}

export const MenuCharacterAvatarEditor: React.FC = () => (
  <EditedAvatarProvider data={useAppSelector(selectCharacterAvatar)}>
    <MenuCharacterEditorWithContext />
  </EditedAvatarProvider>
)

const MenuCharacterEditorWithContext: React.FC = () => {
  const dispatch = useAppDispatch()
  const { openModal, closeModal, isOpen } = useAvatarEditorModal()

  const { editedAvatar } = useEditedAvatar()

  const handleSaveClick = () => {
    dispatch(updateCharacterAvatar(editedAvatar))
    closeModal()
  }

  return (
    <MenuItem onClick={openModal}>
      <Flex alignItems="center">
        <InfoOutlineIcon aria-label="Edit character avatar" mr={2} />
        <Text>Edit Character</Text>
        <Modal size="full" isOpen={isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Your Avatar</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CharacterAvatarEditor />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSaveClick}>
                Save
              </Button>
              <Button variant="ghost" onClick={closeModal}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </MenuItem>
  )
}

export const CharacterAvatarEditor: React.FC<{}> = () => {
  const avatarData = useAppSelector(selectCharacterAvatar)

  return (
    <Box width="100%">
      <Flex alignItems="center" justifyContent="center">
        <Box
          aspectRatio={0.5}
          height="75vh"
          position="relative"
          overflow="hidden"
          boxShadow="md"
        >
          <img
            src="/character/skin-medium.png"
            alt="Character Avatar"
            style={{
              paddingLeft: "25px",
              height: "75vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
          <img
            src="/character/eyes-neutral.png"
            alt="Character Avatar"
            style={{
              paddingLeft: "25px",
              height: "75vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
          <img
            src="/character/outline.png"
            alt="Character Avatar"
            style={{
              paddingLeft: "25px",
              height: "75vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
          {Object.entries(avatarData)?.map(([key, value], index) => (
            <AnimatedAvatarLayer
              key={key}
              name={key}
              index={index}
              src={value}
            />
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

const AnimatedAvatarLayer: React.FC<{
  index: number
  name: string
  src: string
}> = ({ index, src, name }) => {
  const [imageSrc, setImageSrc] = useState(src)
  const [selection, setSelection] = useState(index)

  const { setEditedAvatar } = useEditedAvatar()

  const handleNext = () => {
    const options = AvatarOptionsData[name as keyof typeof AvatarOptionsData] || []
    if (options.length === 0) return

    const nextIndex = (selection + 1) % options.length
    const newSrc = options[nextIndex]
    if (!newSrc) return

    setImageSrc(newSrc)
    setSelection(nextIndex)

    setEditedAvatar(prev => ({
      ...prev,
      [name]: newSrc,
    }))
  }

  const handlePrev = () => {
    const options = AvatarOptionsData[name as keyof typeof AvatarOptionsData] || []
    if (options.length === 0) return

    const prevIndex = (selection - 1 + options.length) % options.length
    const newSrc = options[prevIndex]
    if (!newSrc) return

    setImageSrc(newSrc)
    setSelection(prevIndex)

    setEditedAvatar(prev => ({
      ...prev,
      [name]: newSrc,
    }))
  }
  return (
    <Box position="relative">
          <img
            key={name}
            src={imageSrc}
            alt="Character Avatar"
            style={{
              paddingLeft: "25px",
              height: "75vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
      <Button
        leftIcon={<ArrowLeftIcon />}
        aria-label="Previous Avatar"
        left="15px"
        position="absolute"
        top={`calc(${index * 12}vh + 10vh)`}
        zIndex={2}
        onClick={handleNext}
      >
        {labelTitles[name as keyof typeof labelTitles]}
      </Button>
      <Button
        rightIcon={<ArrowRightIcon />}
        aria-label="Next Avatar"
        right="15px"
        position="absolute"
        top={`calc(${index * 12}vh + 10vh)`}
        zIndex={2}
        onClick={handlePrev}
      >
         {labelTitles[name as keyof typeof labelTitles]}
      </Button>
    </Box>
  )
}

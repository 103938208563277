import { Box, Button, Flex, Link, Stat, StatLabel, StatNumber } from "@chakra-ui/react"
import { EducationMeter } from "../features/game/EducationMeter"
import { HealthMeter } from "../features/game/HealthMeter"
import { RelationshipMeter } from "../features/game/RelationshipMeter"
import SlideViewer from "../features/game/SlideViewer"
import { CharacterText } from "../features/character/CharacterText"
import { AgeCounter } from "../features/game/AgeCounter"
import { CharacterAvatar } from "../features/character/CharacterAvatar"
import { AgencyMeter } from "../features/game/AgencyMeter"
import { useAppSelector } from "../app/hooks"
import {  selectScore } from "../features/game/gameSlice"
import { useAvatarEditorModal } from "../features/character/useAvatarEditorModal"

const MotionBox = Box

const GameSlidePage: React.FC = () => {
  const { openModal } = useAvatarEditorModal();
  
  const gameScore = useAppSelector(selectScore)

  return (
    <Box p={4}>
      {/* Character Name and Age */}
      <Box textAlign="center" mb={{ base: 4, lg: 1 }}>
        <Flex direction="row" alignItems="center" justifyContent="center">
          <CharacterText />
          <Box mx={2} />
          {/* Add some spacing between the text and the counter */}
          <AgeCounter />
        </Flex>
      </Box>

      <Flex
        direction={{ base: "column", lg: "row" }}
        alignItems="center"
        justifyContent="center"
        maxH={{ base: "auto", lg: "85vh" }}
        overflow="auto"
      >
        {/* Left Side: Meters with Drag Animation */}
        <Flex
          direction={{ base: "row", lg: "column" }}
          alignItems="center"
          justifyContent="center"
          mr={{ base: 0, lg: 4 }}
          mb={{ base: 4, lg: 0 }}
          flexWrap={{ base: "wrap", lg: "nowrap" }}
        >
          <MotionBox mb={{ base: 0.6, lg: 1.2 }} mr={{ base: 0.6, lg: 0 }}>
        <AgencyMeter />
          </MotionBox>
          <MotionBox mb={{ base: 0.6, lg: 1.2 }} mr={{ base: 0.6, lg: 0 }}>
        <EducationMeter />
          </MotionBox>
          <MotionBox mb={{ base: 0.6, lg: 1.2 }} mr={{ base: 0.6, lg: 0 }}>
        <HealthMeter />
          </MotionBox>
          <MotionBox mb={{ base: 0.6, lg: 1.2 }} mr={{ base: 0.6, lg: 0 }}>
        <RelationshipMeter />
          </MotionBox>
          <MotionBox mb={{ base: 0.6, lg: 1.2 }} mr={{ base: 0.6, lg: 0 }}>
        <Stat mt={2}>
          <StatLabel>Score</StatLabel>
          <StatNumber>{gameScore}</StatNumber>
        </Stat>
          </MotionBox>
        </Flex>

        {/* Center: SlideViewer */}
        <Box
          flexShrink={0}
          width={{ base: "100%", lg: "65vh"}}
          position="relative"
        >
          <SlideViewer />
        </Box>

        {/* Right Side: Character Image */}
        <Flex>
          <Box ml={4}>
            <Link onClick={openModal}>
          <CharacterAvatar />
          </Link>
            {/* <Button colorScheme="teal" w="100%" onClick={openModal}>
              Edit Avatar
            </Button> */}
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export default GameSlidePage

export default [
  {
    "title": "Default Deck",
    "groups": [
      {
        "title": "Starting Events",
        "requirements": {
          "age": {
            "eq": 0
          }
        },
        "slides": [
          {
            "title": {
              "en": "Middle Income Family"
            },
            "description": {
              "en": "You are born to hardworking parents."
            },
            "image": "/images/01-starting-events_upper-middle-family.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "relationship": 15,
                  "tags": {
                    "add": [
                      "middle_income",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Wealth"
            },
            "description": {
              "en": "You are born to wealthy parents."
            },
            "image": "/images/01-starting-events_rich-family.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "health": 15,
                  "tags": {
                    "add": [
                      "wealthy",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Single Mother"
            },
            "description": {
              "en": "You are born to a single mother."
            },
            "image": "/images/01-starting-events_single-mother.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "agency": 15,
                  "tags": {
                    "add": [
                      "single_mother",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Typical Family"
            },
            "description": {
              "en": "You are born to parents of average wealth."
            },
            "image": "/images/01-starting-events_typical-family.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "agency": 5,
                  "health": 5,
                  "education": 5,
                  "tags": {
                    "add": [
                      "normal",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Early Primary School ",
        "requirements": {
          "age": {
            "gt": 4,
            "lt": 11
          }
        },
        "slides": [
          {
            "title": {
              "en": "Water Fetching"
            },
            "description": {
              "en": "You are sent to fetch water for your morning chores."
            },
            "image": "/images/02-early-primary-school_young-girl-fetching-water.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia walking with a bucket to fetch water, surrounded by spars",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Hurry back home."
                },
                "effects": {
                  "health": 3,
                  "tags": {
                    "add": [
                      "determined"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Take a break."
                },
                "effects": {
                  "health": 2,
                  "tags": {
                    "add": [
                      "rested"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Time Alone"
            },
            "description": {
              "en": "You are left alone for a long period of time. Everyone else in your family is busy."
            },
            "image": "/images/02-early-primary-school_young-girl-sulking-hut.webp",
            "imageAlt": "Black-and-white comic book style image of a young African girl around 10 years old sulking alone behind a simple thatch-roof hut",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Reflect or pray quietly."
                },
                "effects": {
                  "education": 2,
                  "health": 1,
                  "tags": {
                    "add": [
                      "self_reflection",
                      "prayer"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Try to have fun."
                },
                "effects": {
                  "health": 3,
                  "agency": 3,
                  "tags": {
                    "add": [
                      "self_reliant"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Helping with Cooking"
            },
            "description": {
              "en": "You are helping your mother cook over an open fire, carefully tending to the meal."
            },
            "image": "/images/02-early-primary-school_young-girl-helping-cook.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia helping her mother cook over an open fire, carefully tending to the meal",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Ask questions about cooking."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "culinary"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Ask your mother how she is doing."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "family_oriented"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Caring for Animals"
            },
            "description": {
              "en": "You are tasked with feeding and caring for the animals."
            },
            "image": "/images/02-early-primary-school_young-girl-caring-animals.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia feeding chickens and goats with care",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Finish the task quickly."
                },
                "effects": {
                  "health": 3,
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "animal_care"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Seek guidance on how to care for the animals better."
                },
                "effects": {
                  "education": 4,
                  "tags": {
                    "add": [
                      "education",
                      "veterinary_interest",
                      "animal_lover"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Learning to Weave"
            },
            "description": {
              "en": "Your mother is weaving a basket and would like you to join her."
            },
            "image": "/images/02-early-primary-school_young-girl-weaving.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia seated and focused on weaving or mending a basket",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Give up after several mistakes."
                },
                "effects": {
                  "relationship": -4,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Watch closely and ask for help when stuck."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "mentored",
                      "artisan"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Drying Clothes"
            },
            "description": {
              "en": "You are hanging up clothes to dry. The task is nearly finished."
            },
            "image": "/images/02-early-primary-school_young-girl-drying-clothes.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Study while the clothes are drying."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Do other chores."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Maintaining Peace"
            },
            "description": {
              "en": "The adults in your family are arguing."
            },
            "image": "/images/02-early-primary-school_young-girl-maintaining-peace.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia standing quietly while her parents argue",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Stay calm and observe"
                },
                "effects": {
                  "relationship": 2,
                  "education": 1,
                  "tags": {
                    "add": [
                      "peacekeeper"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Find somewhere quiet to be alone"
                },
                "effects": {
                  "agency": 3,
                  "health": 5,
                  "tags": {
                    "add": [
                      "self_reliant"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Sharing a Meal with Mother"
            },
            "description": {
              "en": "You are sitting quietly with your family at the start of a meal."
            },
            "image": "/images/02-early-primary-school_young-girl-waiting-to-eat.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia patiently waiting to eat after her parents finish working, sitting quietly",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Wait to be spoken to."
                },
                "effects": {
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "obedient"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Start a conversation."
                },
                "effects": {
                  "relationship": 2,
                  "agency": 2,
                  "tags": {
                    "add": [
                      "family_bond"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Practice English"
            },
            "description": {
              "en": "Classes are over, and you are passing time by using flashcards to learn your ABCs."
            },
            "image": "/images/02-early-primary-school_reading_flashcards.webp",
            "imageAlt": "A young girl, around five years old, using flashcards to learn ABCs in a well-lit classroom.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Carefully study the flashcards, asking questions when you get stuck."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious",
                      "english_proficiency"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Go home as soon as you can."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Feeling Lonely"
            },
            "description": {
              "en": "You are feeling lonely today."
            },
            "image": "/images/02-early-primary-school_lonely-2.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl indoors sitting alone.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Work on something around the house or do homework."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious",
                      "dutiful"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Try to cheer up by looking for someone to chat with you."
                },
                "effects": {
                  "relationship": 2,
                  "health": 2,
                  "tags": {
                    "add": [
                      "emotional_intelligence"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Caring for Younger Siblings"
            },
            "description": {
              "en": "You are asking to care for your youngest sibling while everyone else is busy."
            },
            "image": "/images/02-early-primary-school_caring-for-younger-siblings.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia caring for a younger sibling.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Find a way to study while keeping the baby close to you."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Spend time with the baby and keep the baby entertained."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful",
                      "child_care"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Older Siblings"
            },
            "description": {
              "en": "Some of your oldest siblings have come to visit and share a meal. They have children around your age and younger."
            },
            "image": "/images/02-early-primary-school_older-siblings.webp",
            "imageAlt": "Black-and-white comic book style image of a family eating food together.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Play with your young neices or nephews."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "family-oriented"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Ask your older siblings about their lives and what they do for work."
                },
                "effects": {
                  "relationship": 2,
                  "education": 1,
                  "tags": {
                    "add": [
                      "wisdom"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Trouble Sleeping"
            },
            "description": {
              "en": "You are frequently ill, and it has led to some trouble sleeping. This is the 4th time you have been too sick to go to school in one quarter."
            },
            "image": "/images/02-early-primary-school_sickly-child.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "strong",
                  "wealthy"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Pray about this hardship."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "sickly",
                      "pious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Think about ways to get special medical help at low cost or through a grant at school."
                },
                "effects": {
                  "relationship": 2,
                  "health": 1,
                  "tags": {
                    "add": [
                      "sickly",
                      "resourceful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Excellent Health"
            },
            "description": {
              "en": "You are rarely ill, and you recognize you are particularly blessed with good health."
            },
            "image": "/images/02-early-primary-school_strong-child.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl appreciating her own muscle.",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "sickly_child"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Thank God for this blessing."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "strong",
                      "pious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Consider getting even stronger through sports."
                },
                "effects": {
                  "relationship": 2,
                  "health": 2,
                  "tags": {
                    "add": [
                      "strong",
                      "sports_interest"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Learning to Mend"
            },
            "description": {
              "en": "Your mother is teaching how to mend with needle and thread."
            },
            "image": "/images/02-early-primary-school_learning-to-mend.webp",
            "imageAlt": "A young girl and her mother sit outside near their home conducting a sewing lesson with needle and thread.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Ask questions about sewing techniques and other ways you practice sewing on your own."
                },
                "effects": {
                  "education": 2,
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "sewing-skills"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Only do enough to appease your mother and hurry on to your studies."
                },
                "effects": {
                  "relationship": 1,
                  "education": 2,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Going to Market"
            },
            "description": {
              "en": "You are tasked with picking up a few things from the market on the way home from school."
            },
            "image": "/images/02-early-primary-school_going-to-market.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Purchase oil and vegetables."
                },
                "effects": {
                  "health": 3,
                  "tags": {
                    "add": [
                      "healthy_food"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Purchase sweets and coffee."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Visit from Auntie"
            },
            "description": {
              "en": "An auntie of yours has come to visit, and she excited to share that she is starting university after many years not in school."
            },
            "image": "/images/02-early-primary-school_strong-role-models.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Ask her about what it was like to leave home."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Tell her about your goals for your own education."
                },
                "effects": {
                  "education": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Physical Intimacy"
            },
            "description": {
              "en": "Your boyfriend would like to have sex, and he has a condom. You are somewhere private, he claims to have been tested for STIs and you trust him."
            },
            "image": "/images/02-early-primary-school_hands-touching.png",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "has_boyfriend"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell him you are not comfortable having sex."
                },
                "effects": {
                  "agency": 13,
                  "tags": {
                    "add": [
                      "self-assured"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Tell him you are comfortable having sex."
                },
                "effects": {
                  "relationship": 12,
                  "tags": {
                    "add": [
                      "safe_physical_intimacy"
                    ],
                    "remove": [
                      "virgin"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Secret Meeting"
            },
            "description": {
              "en": "A boy at school would like to show you something in secret. He says it is a special type of game."
            },
            "image": "/images/02-early-primary-school_new_friend.png",
            "imageAlt": "",
            "type": "trigger",
            "requirements": {
              "tags": {
                "not": [
                  "special_game",
                  "exit_special_game"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Go with him."
                },
                "effects": {
                  "health": -1,
                  "age": -1,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "special_game"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Tell him you would like to stay outside and play a different game."
                },
                "effects": {
                  "relationship": -1,
                  "age": -1,
                  "agency": 2,
                  "tags": {
                    "add": [
                      "exit_special_game"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Secret Meeting Continued"
            },
            "description": {
              "en": "He tells you the game involves tickling each other until one person is too nervous to continue. He reaches toward your hip to demonstrate."
            },
            "image": "/images/02-early-primary-school_new_friend_2.webp",
            "imageAlt": "",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "special_game"
                ],
                "not": [
                  "special_game_2",
                  "exit_special_game"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell him to stop, you don't feel comfortable with this game."
                },
                "effects": {
                  "agency": 4,
                  "age": -1,
                  "relationship": -1,
                  "tags": {
                    "add": [
                      "exit_special_game"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Go along with it."
                },
                "effects": {
                  "agency": -3,
                  "age": -1,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "special_game_2"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Secret Meeting Part 3"
            },
            "description": {
              "en": "You continue the game, and suddenly he pinches your chest and laughs wickedly."
            },
            "image": "/images/02-early-primary-school_new_friend_3.webp",
            "imageAlt": "",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "special_game_2"
                ],
                "not": [
                  "special_game_3",
                  "exit_special_game"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Push him off and run out."
                },
                "effects": {
                  "agency": 4,
                  "age": -1,
                  "health": -1,
                  "tags": {
                    "add": [
                      "exit_special_game"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Keep trying to win the game."
                },
                "effects": {
                  "health": -3,
                  "age": -1,
                  "agency": -2,
                  "tags": {
                    "add": [
                      "special_game_3"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Secret Meeting Part 4"
            },
            "description": {
              "en": "As you try to get him to give up, he places your hand on his genitals. He laughs and calls you a surprisingly easy girl."
            },
            "image": "/images/02-early-primary-school_special-game-bad-ending.webp",
            "imageAlt": "",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "special_game_3"
                ],
                "not": [
                  "exit_special_game"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Slap him and run."
                },
                "effects": {
                  "agency": 4,
                  "age": -1,
                  "health": -1,
                  "tags": {
                    "add": [
                      "exit_special_game"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Cry and leave."
                },
                "effects": {
                  "health": -3,
                  "age": -1,
                  "agency": -2,
                  "tags": {
                    "add": [
                      "exit_special_game"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Primary School ",
        "requirements": {
          "age": {
            "gt": 7,
            "lt": 10
          }
        },
        "slides": [
          {
            "title": {
              "en": "First Period"
            },
            "description": {
              "en": "You are in the bathroom at school and discover some blood: you have gotten your period!"
            },
            "image": "/images/03-primary-school_young-girl-shocked.webp",
            "imageAlt": "A young girl looks down in shock during a trip to the bathroom, it is implied she had gotten her first menstrual cycle.",
            "type": "trigger",
            "requirements": {
              "age": {
                "eq": 11
              },
              "tags": {
                "not": [
                  "first_period"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Don't tell anyone. Use toilet paper as a sanitary napkin for the day."
                },
                "effects": {
                  "health": -3,
                  "tags": {
                    "add": [
                      "first_period"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Ask for help from a trusted female teacher."
                },
                "effects": {
                  "health": 3,
                  "agency": 3,
                  "tags": {
                    "add": [
                      "resourceful",
                      "self_advocate",
                      "first_period"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Stranger Offers a Ride"
            },
            "description": {
              "en": "A strange man stops his car alongside you and offers you a ride home. He seems friendly, but you have never seen him before."
            },
            "image": "/images/03-primary-school_creepy offer walking home along a dirt road.webp",
            "imageAlt": "A comic book style image of a man alone in in a car offers a ride to a young girl walking home alone a dirt road.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Accept the offer."
                },
                "effects": {
                  "relationship": -3,
                  "tags": {
                    "add": [
                      "abduction_risk",
                      "risky_behavior"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline the offer and look for a group to join or a trusted adult."
                },
                "effects": {
                  "relationship": 2,
                  "agency": 2,
                  "tags": {
                    "add": [
                      "self_advocacy",
                      "street_smarts"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Bullied by the Boys"
            },
            "description": {
              "en": "A group of boys are openly insulting you. They say you will ruin the game if you join them, because you are a girl."
            },
            "image": "/images/03-primary-school_mean-boy-group.webp",
            "imageAlt": "Black-and-white comic book style image of a young African girl, and three boys the same age. She is being sneered at and kept from playing the game.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Dismiss them and play somewhere else."
                },
                "effects": {
                  "health": 1,
                  "tags": {
                    "add": [
                      "self_reliant"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Stand up to them! Tell them you could easily beat them at sports, and demand they give you a chance."
                },
                "effects": {
                  "relationship": -2,
                  "agency": 3,
                  "tags": {
                    "add": [
                      "sports-interest",
                      "out-spoken"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Gossiping Classmates"
            },
            "description": {
              "en": "Some of you classmates are gossipping about inappropriate topics, they signal for you to join them."
            },
            "image": "/images/03-primary-school_gossip.webp",
            "imageAlt": "Some students are gossiping in the background while our character, in the foreground, debates joining them.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Join them."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "gossip"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Do something else and ignore their conversation."
                },
                "effects": {
                  "agency": 2,
                  "tags": {
                    "add": [
                      "pious"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Strange Shop Keeper"
            },
            "description": {
              "en": "A shop that you usually go to after school has a new keeper. He tells you look beautiful and will give you a discount if you talk to him behind the counter for a little while."
            },
            "image": "/images/03-primary-school_new_creepy_shop_owner.webp",
            "imageAlt": "A young girl stands in front of a shop owner in a convenience store. He has a smug or sinister expresssion.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Refuse, and leave the shop."
                },
                "effects": {
                  "agency": 3,
                  "tags": {
                    "add": [
                      "street_smart"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Stay with him and chat."
                },
                "effects": {
                  "health": -2,
                  "tags": {
                    "add": [
                      "abduction_risk",
                      "risky_behavior"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "The Price of a Period"
            },
            "description": {
              "en": "Your aunt has suggested that when the time comes, you will observe tradition, and stay in isolation after your first period to recieve the traditional Chinamwali rites, and initiate you into Womanhood."
            },
            "image": "/images/03-primary-school_isolation-hut-offer.webp",
            "imageAlt": "Two women sit in a simple hut in rural Zambia, and adress a young girl. They are stern or serious, but relaxed expressions.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Nod and say nothing."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Ask questions, signalling you are uncomfortable just following tradition for the sake of tradition."
                },
                "effects": {
                  "relationship": -2,
                  "agency": 3,
                  "tags": {
                    "add": [
                      "out-spoken"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Chinamwali"
            },
            "description": {
              "en": "Your family has chosen to go through with the Initiation, and you will stay with the woman giving Chinamwali lessons for 7 days. You will potentially fall behind in your school work."
            },
            "image": "/images/03-primary-school_in-isolation-hut.webp",
            "imageAlt": "A image of two silhouetted figures: a young girl being introduced to a middle aged woman wearing a head covering and jewelry, and a long patterned skirt. The woman is extending her hand toward the timid girl who is standing outside of her family's hut in rural Zambia",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "first_period"
                ],
                "not": [
                  "continued_chinamwali"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Protest and beg to not go through with it."
                },
                "effects": {
                  "relationship": -3,
                  "education": 3,
                  "tags": {
                    "add": [
                      "out_spoken",
                      "refused_chinamwali"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Prepare to leave and go through with the Initiation."
                },
                "effects": {
                  "relationship": 2,
                  "agency": -3,
                  "tags": {
                    "add": [
                      "chinamwali",
                      "continued_chinamwali"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Chinamwali"
            },
            "description": {
              "en": "You have completed your Chinamwali lessons. You have yet to reflect on what you learned, but are now called to dance as the final part of the initiation ceremony. Many men are watching from the side lines, who now consider you a woman."
            },
            "image": "/images/03-primary-school_chinamwali-celebration.webp",
            "imageAlt": "A Brightly lit image of a group of women conducting a dance circle as a part of the Chinamwali initiation ceremony.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "first_period",
                  "continued_chinamwali"
                ],
                "not": [
                  "refused_chinamwali",
                  "completed_chinamwali"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Enjoy the festivities."
                },
                "effects": {
                  "relationship": -3,
                  "education": 3,
                  "tags": {
                    "add": [
                      "male_attention",
                      "chinamwali_celebration",
                      "missed_school",
                      "completed_chinamwali"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Try to leave as soon as possible."
                },
                "effects": {
                  "relationship": -2,
                  "agency": 3,
                  "tags": {
                    "add": [
                      "completed_chinamwali"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Playing Football"
            },
            "description": {
              "en": "You are playing football with friends at lunch time, and really enjoying yourself."
            },
            "image": "/images/03-primary-school_soccer-inspiration.webp",
            "imageAlt": " Black and white image of a young girl in a school uniform playing soccer with her friends at lunchtime.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Make a plan to keep practicing and improving your skills."
                },
                "effects": {
                  "health": 3,
                  "tags": {
                    "add": [
                      "sports_interest"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Keep having fun with your friends regardless of the sport."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "friendship"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Playing Chess"
            },
            "description": {
              "en": "You are learning to play chess with your science teacher at lunch time, and it's quite fun!"
            },
            "image": "/images/03-primary-school_chess-inspiration.webp",
            "imageAlt": " Black and white image of a young girl in a school uniform playing chess with her teacher at lunchtime.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Make a plan to keep practicing and improving your skills."
                },
                "effects": {
                  "health": 3,
                  "tags": {
                    "add": [
                      "chess_interest"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Thank him for spending time with you even though the game doesn't interest you."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "mentorship"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Book Has Caught Your Eye"
            },
            "description": {
              "en": "You find an old medical textbook with diagrams, and descriptions of cures and treatments."
            },
            "image": "/images/03-primary-school_medical-inspiration.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl looking at a book with the caduceus or medical symbol on the front.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Go back to your classwork and put the book down."
                },
                "effects": {
                  "education": 1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Read the book more thoroughly and ask if you can keeping reading it after class."
                },
                "effects": {
                  "education": 2,
                  "tags": {
                    "add": [
                      "medical_interest"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Dress Has Caught Your Eye"
            },
            "description": {
              "en": "You see beautiful dress on the walk home from school."
            },
            "image": "/images/03-primary-school_fashion-inspiration.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl looking at a dress at the market.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Think about what it would be like to make a dress like that."
                },
                "effects": {
                  "education": 2,
                  "tags": {
                    "add": [
                      "fashion_interest"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Keep walking after appreciating the dress and being friendly to the dress seller."
                },
                "effects": {
                  "education": 1,
                  "relationship": 1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Finding Creativity"
            },
            "description": {
              "en": "You find yourself drawn to make things during your free time, sometimes creating stories around what you design."
            },
            "image": "/images/03-primary-school_arts-inspiration.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl creating figurines and drawing in the mud in her free time happily.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue to make time to be creative and share your creations with others."
                },
                "effects": {
                  "education": 2,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "arts_interest"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Release this unnecessary distraction so you can focus on school and duties at home."
                },
                "effects": {
                  "education": 1,
                  "relationship": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Book Catches Your Eye in the Library"
            },
            "description": {
              "en": "You find yourself drawn to an old book of maps and photos from other places."
            },
            "image": "/images/03-primary-school_travel-inspiration.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl creating figurines and drawing in the mud in her free time happily.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Imagine a life where you could travel anywhere in the world."
                },
                "effects": {
                  "education": 2,
                  "tags": {
                    "add": [
                      "travel_interest"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Release this unnecessary distraction so you can focus on school and duties at home."
                },
                "effects": {
                  "education": 1,
                  "relationship": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Putting It Back Together"
            },
            "description": {
              "en": "One of your younger uncles is fixing a radio and would like you to join him."
            },
            "image": "/images/03-primary-school_eng-inspiration.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl creating figurines and drawing in the mud in her free time happily.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Help him put it together, asking questions about each part along the way."
                },
                "effects": {
                  "education": 2,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "eng_interest"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Watch quietly and patiently so he can keep his concentration."
                },
                "effects": {
                  "education": 1,
                  "relationship": 2,
                  "agency": -1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Girls' Club"
            },
            "description": {
              "en": "You've joined Girls' Club! You will meet new friends, recieve homework help, and learn about how to take care yourself as a growing girl."
            },
            "image": "/images/03-primary-school_join-girls-club.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "trigger",
            "requirements": {
              "age": {
                "eq": 10
              },
              "tags": {
                "not": [
                  "joined_girls_club"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Encourage a friend to join who is not currently in Girls' Club."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "joined_girls_club"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Start by asking for help with your studies."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "joined_girls_club"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Meeting Tourists"
            },
            "description": {
              "en": "A group of tourists are driving by, driven by a guide you do not recognize. They wave at you to approach the car with friendly expresions."
            },
            "image": "/images/03-primary-school_tourists.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Greet them and find out what they want."
                },
                "effects": {
                  "health": -1,
                  "tags": {
                    "add": [
                      "risky_behavior"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Ignore them and walk the other way."
                },
                "effects": {
                  "health": 2,
                  "tags": {
                    "add": [
                      "street-smart"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Girls' Club"
            },
            "description": {
              "en": "You are in Girls' Club. A question has come up: what is consent?"
            },
            "image": "/images/03-primary-school_girls_club_consent_q.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "joined_girls_club"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Consent is when you are enthusiastic and knowledgeable about what are doing, and you know you are safe and can stop at anytime."
                },
                "effects": {
                  "education": 13,
                  "tags": {
                    "add": [
                      "answered_correctly_consent"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Consent is something that can be given on accident and not taken back."
                },
                "effects": {
                  "education": -13,
                  "agency": -12,
                  "tags": {
                    "add": [
                      "answered_incorrectly_consent"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Late Primary School ",
        "requirements": {
          "age": {
            "gt": 8,
            "lt": 13
          }
        },
        "slides": [
          {
            "title": {
              "en": "Poorly Fitting Clothes"
            },
            "description": {
              "en": "You notice that young clothes are fitting poorly - they are too small."
            },
            "image": "/images/04-late-primary-school_poorly-fitting-clothes.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl inspecting a dress at home in rural Zambia.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Try to adjust your clothes yourself using sewing skills and scrap fabric."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "sewing-skills",
                      "resourceful"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Ask your family for new clothes."
                },
                "effects": {
                  "agency": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "New Clothes"
            },
            "description": {
              "en": "Your newly mended clothes look even better than before as they fit perfectly."
            },
            "image": "/images/04-late-primary-school_new-clothes.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Offer to mend clothes for others."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "fashion-interest",
                      "helpful"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Show off your new clothes to your friends"
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Bully's Demand"
            },
            "description": {
              "en": "A boy at school is trying to bully you into being his girlfriend. He says he will start a rumor you already had sex, and then cheated on him with his brother if you do not agree."
            },
            "image": "/images/04-late-primary-school_forceful-boyfriend-request.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Give in to his demands for a day, and then try to break up public before it goes too far."
                },
                "effects": {
                  "health": -3,
                  "agency": -3,
                  "tags": {
                    "add": [
                      "gave_in_to_bad_boy"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Run to get help from an adult."
                },
                "effects": {
                  "relationship": 2,
                  "education": 2,
                  "tags": {
                    "add": [
                      "self-reliant",
                      "fight_back"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Tourists"
            },
            "description": {
              "en": "while walking home, a group of tourists approaches you, offering money if you would like to be their guide."
            },
            "image": "/images/04-late-primary-school_creepy-tourists.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Go with them."
                },
                "effects": {
                  "health": -3,
                  "tags": {
                    "add": [
                      "risky-behavior",
                      "went_with_tourists"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Act as though you do not understand them and leave quickly."
                },
                "effects": {
                  "health": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Tourists Continued"
            },
            "description": {
              "en": "The tourists have decided to drop you off by the road. They are bored of you, and you have to walk home"
            },
            "image": "/images/04-late-primary-school_creepy_tourists_3.webp",
            "imageAlt": "A black-and-white hand-drawn comic book-style illustration showing a close-up interior of a safari jeep driven by two European tourists. An 8-year-old African girl with a short sits between them.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "on_the_road"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Thank God that they did not hurt you."
                },
                "effects": {
                  "health": 1,
                  "relationship": 1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Hope to see them again when you are feeling less boring."
                },
                "effects": {
                  "health": -2,
                  "agency": -4,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Secret Boyfriend"
            },
            "description": {
              "en": "you discover your close friend has a boyfriend that she never told you about."
            },
            "image": "/images/04-late-primary-school_friend-has-bf.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Warn her of the dangers and tell her to stop."
                },
                "effects": {
                  "relationship": -3,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Tell her shouldn't have kept a secret and be supportive."
                },
                "effects": {
                  "relationship": 2,
                  "education": -1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Mother's Advice"
            },
            "description": {
              "en": "Your mother reminds you that you have more opportunities now than she did when she was your age. She reminds you that your ability to choose goes away if you don't use it."
            },
            "image": "/images/04-late-primary-school_motherly-advice.webp",
            "imageAlt": "A black-and-white 90s comic book-style illustration showing a young African girl in a school uniform receiving motherly advice at home.",
            "type": "trigger",
            "requirements": {
              "age": {
                "eq": 12
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell her you understand and are doing your best."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Ask her what she wishes she could have done."
                },
                "effects": {
                  "relationship": 4,
                  "tags": {
                    "add": [
                      "dutiful",
                      "motherly_advice2"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Physical Intimacy"
            },
            "description": {
              "en": "Your boyfriend would like to have sex, and he has a condom. You are somewhere private, he claims to have been tested for STIs and you trust him."
            },
            "image": "/images/04-late-primary-school_hands-touching.png",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "has_boyfriend"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell him you are not comfortable having sex."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Tell him you are comfortable having sex."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Excellent Marks"
            },
            "description": {
              "en": "Your excellent performance in school has been noticed, and you are eligible for scholaships to boarding schools."
            },
            "image": "/images/04-late-primary-school_good-grades.webp",
            "imageAlt": "A black and white comic book style illustration featuring a girl who is celebrating her excellent marks. The scene is minimalistic.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Tell the Girls' Club to remove your name from the list so it can go to someone else."
                },
                "effects": {
                  "education": -3,
                  "tags": {
                    "add": [
                      "self_sacrifice",
                      "no_scholarship_secondary_school"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Celebrate and begin planning your secondary school experience."
                },
                "effects": {
                  "education": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Scholaship Awarded"
            },
            "description": {
              "en": "Due to your excellent grades, you have earned a scholarship to a boarding school for secondary school! Congratulations!"
            },
            "image": "/images/04-late-primary-school_scholarship-to-katete.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "declined_boarding_school",
                  "bad-grades"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Accept the award, and go off to live at secondary boarding school."
                },
                "effects": {
                  "education": 6,
                  "tags": {
                    "add": [
                      "studious",
                      "good-boarding-school"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Stay home to continue to help caring for your family."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "declined_boarding_school"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "You Like Each Other"
            },
            "description": {
              "en": "You and a boy your age like each other, and he asks if you would be his girlfriend."
            },
            "image": "/images/04-late-primary-school_new-relationship-primary.png",
            "imageAlt": ".",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Say yes!"
                },
                "effects": {
                  "health": 1,
                  "relationship": 5,
                  "tags": {
                    "add": [
                      "has_boyfriend"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Say no."
                },
                "effects": {
                  "relationship": -2,
                  "health": 3,
                  "tags": {
                    "add": [
                      "declined_boyfriend"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Skipping Protection"
            },
            "description": {
              "en": "Your new boyfriend doesn’t want to use protection during intimacy, saying it’s unnecessary."
            },
            "image": "/images/04-late-primary-school_not-necessary.webp",
            "imageAlt": "A young girl looking uncertain as her boyfriend dismisses her concerns.",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "has_boyfriend"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Agree to avoid conflict."
                },
                "effects": {
                  "health": -3,
                  "tags": {
                    "add": [
                      "risked-STI"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Insist on protection or refuse intimacy."
                },
                "effects": {
                  "relationship": -1,
                  "health": 3,
                  "tags": {
                    "add": [
                      "avoided-STI"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Early Highschool ",
        "requirements": {
          "age": {
            "gt": 12,
            "lt": 18
          }
        },
        "slides": [
          {
            "title": {
              "en": "First Day"
            },
            "description": {
              "en": "A picture of a dorm room in Zambia. A Landscape can be seem in the window."
            },
            "image": "/images/05-early-highschool_first-day.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Get situated in your new room."
                },
                "effects": {
                  "health": 1,
                  "tags": {
                    "add": [
                      "well-rested"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Get to know the other girls."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "social-butterfly"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Poor Marks"
            },
            "description": {
              "en": "You have unfortunately recieved poor marks in one of your classes."
            },
            "image": "/images/05-early-highschool_bad-grade.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl looking down at an exam with poor marks.",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "studious",
                  "mentored"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Seek out a tutor or extra help from teachers."
                },
                "effects": {
                  "education": 3,
                  "agency": 1,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Continue studying alone as you have been."
                },
                "effects": {
                  "relationship": 0,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "High Marks"
            },
            "description": {
              "en": "You have recieved high marks in one of your classes!"
            },
            "image": "/images/05-early-highschool_good-grade.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl looking down at an exam with high marks.",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "studious",
                  "mentored"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Celebrate and feel proud of yourself."
                },
                "effects": {
                  "education": 3,
                  "health": 1,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "high-marks"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Continue to study hard and maintain your grades overall."
                },
                "effects": {
                  "relationship": 0,
                  "education": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Study Time"
            },
            "description": {
              "en": "You are spending some time studying."
            },
            "image": "/images/05-early-highschool_study-time.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl studying in her dorm room.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Focus on maths."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "prioritized-math"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Focus on English."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "prioritized-english"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Alone After Class"
            },
            "description": {
              "en": "One of your male teachers is making you uncomfortable. He asked you to stay late to discuss your grades, but now that the conversation is over, he doesn't seem to want to let you leave. A sickly silence falls on the room, and he stands between you and the door."
            },
            "image": "/images/05-early-highschool_weird-teacher.webp",
            "imageAlt": "A man's silhouette blocks the doorway in an off-putting classroom.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Stay silent and wait for him to tell you want he wants."
                },
                "effects": {
                  "health": -3,
                  "tags": {
                    "add": [
                      "weird-teacher-event",
                      "report-weird-teacher"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Remind him your friends know you are here, and are waiting for you. Firmly tell him to move."
                },
                "effects": {
                  "relationship": 2,
                  "health": 2,
                  "tags": {
                    "add": [
                      "report-weird-teacher"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Chess Club"
            },
            "description": {
              "en": "You have an opportunity to start a Chess Club with your fellow classmates."
            },
            "image": "/images/05-early-highschool_chess-club.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Join the new Chess Club."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "chess-interest"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Do other chores"
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Poetry Club"
            },
            "description": {
              "en": "A group students who enjoy creative writing are discussing creating a Poetry Club."
            },
            "image": "/images/05-early-highschool_poetry-club.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Join the new poetry club."
                },
                "effects": {
                  "education": 3,
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "poetry_club"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline and focus on more important things."
                },
                "effects": {
                  "relationship": -1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Film Club"
            },
            "description": {
              "en": "Your school recieved a grant to purchase some cameras on the condition they go to a student led Film Club."
            },
            "image": "/images/05-early-highschool_film-club.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Join the new Film Club."
                },
                "effects": {
                  "education": 3,
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "film_club"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline and focus on more important things."
                },
                "effects": {
                  "relationship": -1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Sports Training"
            },
            "description": {
              "en": "A Physical Education teacher has noticed your skills in sport, and encourages you to join a competitive team."
            },
            "image": "/images/05-early-highschool_sports-training.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Join the competitive team and recieve more training."
                },
                "effects": {
                  "education": 3,
                  "health": 3,
                  "tags": {
                    "add": [
                      "sports_training"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Thank the teacher for the compliment but decline the offer."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Family Visit"
            },
            "description": {
              "en": "Your family has come to visit you!"
            },
            "image": "/images/05-early-highschool_family-visit.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Spend the day with them."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Tell them you are too busy with your studies to see them today."
                },
                "effects": {
                  "relationship": -4,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Chess Tournament"
            },
            "description": {
              "en": "The chess club has been invited to compete in a Chess Tournament in Lusaka!"
            },
            "image": "/images/05-early-highschool_chess-tournament.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Compete in Lusaka with your chess club."
                },
                "effects": {
                  "education": 3,
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "confident"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Do not compete due to your lack of skill."
                },
                "effects": {
                  "relationship": -2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Visit to the School Chapel"
            },
            "description": {
              "en": "You are visiting the school chapel today."
            },
            "image": "/images/05-early-highschool_school_chapel.webp",
            "imageAlt": "A black and white comic book-style illustration of the school chapel at Katete All Girls Boarding School in Zambia. The chapel is a modest brick building.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Pray for your family and important people in your life."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Seek guidance."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "pious"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Organize the Library"
            },
            "description": {
              "en": "You and a few friends are asked to help organize the library."
            },
            "image": "/images/05-early-highschool_help-library.webp",
            "imageAlt": "Black-and-white comic book style image of three girls happily organizing a library.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Quietly get lost in one of the books while your friends work."
                },
                "effects": {
                  "education": 3,
                  "relationship": -3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Chat about school and your friends while working."
                },
                "effects": {
                  "relationship": 4,
                  "tags": {
                    "add": [
                      "friendship"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Offered a Drink"
            },
            "description": {
              "en": "A friend of yours asks you to come with her while she picks up a gift from an older friend. Surprisingly, it is alcohol, and she offers some to you."
            },
            "image": "/images/05-early-highschool_alcohol-offer.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Decline and excuse yourself to avoid trouble."
                },
                "effects": {
                  "agency": 2,
                  "tags": {
                    "add": [
                      "declined_alcohol"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Join them and stick around even though you are nervous."
                },
                "effects": {
                  "relationship": 2,
                  "education": -1,
                  "tags": {
                    "add": [
                      "risky_behavior",
                      "accepted_alcohol"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "First Exam"
            },
            "description": {
              "en": "You are taking your first secondary school exam. You are a bit nervous."
            },
            "image": "/images/05-early-highschool_exam-highschool.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in silhouette taking an exam.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Read every question before you begin, and then try to answer each even if you are unsure."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "test_taking_strategy"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Do each question one at a time until completion, even if you get stuck."
                },
                "effects": {
                  "education": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Something Isn't Right"
            },
            "description": {
              "en": "A trusted female staff member has noticed some odd behaviour from you, and requested to speak with you. She gives you a chance to explain yourself."
            },
            "image": "/images/05-early-highschool_report-weird-teacher.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "trigger",
            "requirements": {
              "tags": {
                "has": "weird_teacher_event"
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell her about your inappropriate treatment by your male teacher."
                },
                "effects": {
                  "education": 1,
                  "agency": 5,
                  "tags": {
                    "add": [
                      "self-advocate"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Tell her you are simply having trouble focusing due to personal problems and social life."
                },
                "effects": {
                  "relationship": -2,
                  "health": -1,
                  "agency": -5,
                  "tags": {
                    "add": [
                      "kept_quiet"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Early Marriage Pressure"
            },
            "description": {
              "en": "Your family says marriage is a better future than continuing school."
            },
            "image": "/images/05-early-highschool_parents_fighting.webp",
            "imageAlt": "A girl looking anxious as her family discusses marriage plans.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Agree to the marriage."
                },
                "effects": {
                  "health": -13,
                  "relationship": 12,
                  "tags": {
                    "add": [
                      "early-marriage"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Explain you want to finish your education first."
                },
                "effects": {
                  "relationship": -2,
                  "health": 10,
                  "tags": {
                    "add": [
                      "delayed-marriage"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "High School ",
        "requirements": {
          "age": {
            "gt": 13,
            "lt": 18
          }
        },
        "slides": [
          {
            "title": {
              "en": "Secret Admirer"
            },
            "description": {
              "en": "You have a recieved recieved a secret love note!"
            },
            "image": "/images/06-highschool_secret-admirer.webp",
            "imageAlt": "Black-and-white comic book style close-up image of a woman holding an envelope with a heart sticker on it.",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "has_boyfriend"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Ignore the letter. If they were serious, the sender would reveal themselves."
                },
                "effects": {
                  "education": 1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Try to figure out the identity of the sender with help from your friends."
                },
                "effects": {
                  "relationship": 4,
                  "tags": {
                    "add": [
                      "social-butterfly"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Burning Pain"
            },
            "description": {
              "en": "You have a terrible pain whenever you urinate."
            },
            "image": "/images/06-highschool_infection.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "virgin"
                ],
                "and": [
                  "no-protection",
                  "sexually_transmitted_infection",
                  "SA"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Talk to an adult about finding medical care or go to the school infirmary."
                },
                "effects": {
                  "health": 3,
                  "tags": {
                    "add": [
                      "go_to_nurse"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Try to solve it on your own."
                },
                "effects": {
                  "health": -4,
                  "tags": {
                    "add": [
                      "sexually_transmitted_infection"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Late Night Study"
            },
            "description": {
              "en": "You are studying late at night."
            },
            "image": "/images/06-highschool_late-night-study.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue studying until you are too tired to continue."
                },
                "effects": {
                  "education": 1,
                  "tags": {
                    "add": [
                      "sleep_deprived"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Sleep now even if you have not finished your work."
                },
                "effects": {
                  "health": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Missed Period"
            },
            "description": {
              "en": "You've missed your period. Could you be pregnant?"
            },
            "image": "/images/06-highschool_missed_period.webp",
            "imageAlt": "An illustration of a calendar with a period marked and a question mark",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "has_period",
                  "has_boyfriend"
                ],
                "not": [
                  "virgin"
                ],
                "or": [
                  "condom_failure"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Seek a pregnancy test to find out."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "pregnancy_test_at_clinic"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Wait and see."
                },
                "effects": {
                  "health": -2,
                  "tags": {
                    "add": [
                      "anxious"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Late Highschool",
        "requirements": {
          "age": {
            "gt": 18,
            "lt": 20
          }
        },
        "slides": [
          {
            "title": {
              "en": "Date With Boyfriend"
            },
            "description": {
              "en": "Your boyfriend has taken you on a nice date. He is happy to go at whatever pace makes you comfortable."
            },
            "requirements": {
              "tags": {
                "and": [
                  "has-boyfriend"
                ]
              }
            },
            "image": "/images/07-late-highschool_nice-boyfriend.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Kiss him."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "relationship"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Hold his hand."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful",
                      "relationship"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Violent Outburst"
            },
            "description": {
              "en": "Your boyfriend is having a drunk, angry tantrum after losing his job."
            },
            "requirements": {
              "tags": {
                "and": [
                  "has-boyfriend"
                ]
              }
            },
            "image": "/images/07-late-highschool_mean-boyfriend.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Calm him down."
                },
                "effects": {
                  "relationship": 3,
                  "agency": -2,
                  "tags": {
                    "add": [
                      "bad-boyfriend"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Leave him."
                },
                "effects": {
                  "relationship": -2,
                  "agency": 4,
                  "tags": {
                    "remove": [
                      "has-boyfriend"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "How to Write a Grant"
            },
            "description": {
              "en": "A teacher of yours is sitting during lunch with a notebook. He offers to give you and your friend a demonstration on grant writing, a way to recieve money for educational or artisitc projects."
            },
            "image": "/images/07-late-highschool_grant-writing.webp",
            "imageAlt": "A black and white comic book style illustration of two Zambian girls and their teacher, who is giving a demonstration on grant writing.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Stay and ask questions, even making suggestions for what projects should receive funding."
                },
                "effects": {
                  "education": 3,
                  "agency": 3,
                  "tags": {
                    "add": [
                      "grant-writing"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Excuse yourself to focus on more important things."
                },
                "effects": {
                  "education": -2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Helpful Nurse"
            },
            "description": {
              "en": "You have met with a nurse about your health issue, and she is eager to help you. She asks if there is anything else you need?"
            },
            "image": "/images/07-late-highschool_nurse-help-older.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Ask for condoms or other contraceptives."
                },
                "effects": {
                  "health": 2,
                  "tags": {
                    "add": [
                      "recieved_contraceptives"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Ask for menstrual products and cramp reduction medication"
                },
                "effects": {
                  "health": 2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Physical Intimacy"
            },
            "description": {
              "en": "Your boyfriend would like to have sex, and he has a condom. You are somewhere private, he claims to have been tested for STIs and you trust him."
            },
            "image": "/images/07-late-highschool_hands-touching.png",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "has_boyfriend"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell him you are not comfortable having sex."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Tell him you are comfortable having sex."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "His Uncle's Home"
            },
            "description": {
              "en": "Your boyfriend would like to have sex, and he has taken you to his uncle's house while his uncle is at work."
            },
            "image": "/images/07-late-highschool_shady-place.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "has_boyfriend"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell him you are not comfortable having sex."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Tell him you are open to it, but you have some questions."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "ask-pre-sex-questions"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Early Adulthood",
        "requirements": {
          "age": {
            "gt": 19,
            "lt": 26
          }
        },
        "slides": [
          {
            "title": {
              "en": "Resume Writing"
            },
            "description": {
              "en": "A teacher of yours is mentoring you on how to write a resume. She asks what your main focus will be before you begin."
            },
            "image": "/images/08-early-adulthood_resume-writing.webp",
            "imageAlt": "A black and white comic book style illustration of a 17-year-old Zambian girl receiving resume writing help from an older female teacher.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Tell her you will focus on your creativity, the Arts, and your communication skills."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "arts_career"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Tell her you will focus on science, engineering, and medicine."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "engineering_career"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Adulthood ",
        "requirements": {
          "age": {
            "gt": 18,
            "lt": 27
          }
        },
        "slides": [
          {
            "title": {
              "en": "Wildlife Career Offer"
            },
            "description": {
              "en": "You are offered a junior position at a Wildlife Sanctuary that offers tours and conducts research."
            },
            "image": "/images/09-adulthood_wildlife-career-offer.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "accepted_job_offer"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Accept the offer."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "wildlife_career",
                      "accepted_job_offer"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline the offer. You are more interested in something else."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "self-directed"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Wildlife Career Offer"
            },
            "description": {
              "en": "You are offered a scholarship to the nursing school of your choice!"
            },
            "image": "/images/09-adulthood_medical-career-offer.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "accepted_job_offer"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Accept the offer."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "medical_career",
                      "accepted_job_offer"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline the offer. You are more interested in something else."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "self-directed"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Engineering Career Offer"
            },
            "description": {
              "en": "You are offered a junior position at a electronic company as a computer engineer."
            },
            "image": "/images/09-adulthood_engineer-career-offer.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "accepted_job_offer"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Accept the offer."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "engineering_career",
                      "accepted_job_offer"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline the offer. You are more interested in something else."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "self-directed"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Fashion Career Offer"
            },
            "description": {
              "en": "You are offered a junior position at a fashion agency as a clothing desinger."
            },
            "image": "/images/09-adulthood_fashion-career-offer.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "accepted_job_offer"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Accept the offer."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "fashion_career",
                      "accepted_job_offer"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline the offer. You are more interested in something else."
                },
                "effects": {
                  "relationship": 2,
                  "agency": 2,
                  "tags": {
                    "add": [
                      "self-directed"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Big Wedding"
            },
            "description": {
              "en": "You and your boyfriend decide to have a big, traditional wedding. Family and friends come from all over to celebrate with you."
            },
            "image": "/images/09-adulthood_big-wedding.webp",
            "imageAlt": "Black-and-white comic book style image of a bride and groom at a traditional Zambian wedding",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "good-boyfriend",
                  "has-boyfriend"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Enjoy the celebration!"
                },
                "effects": {
                  "relationship": 5,
                  "tags": {
                    "add": [
                      "married",
                      "has_husband"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Sports Career Offer"
            },
            "description": {
              "en": "Your sports talent has been noticed and you have been given a scholarship to play women's football at university."
            },
            "image": "/images/09-adulthood_sports-career-offer.webp",
            "imageAlt": "An illustration depicting a sports career offer, perhaps showing a contract or a coach with a team.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Accept the offer"
                },
                "effects": {
                  "agency": 5,
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "sports_career"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline the offer"
                },
                "effects": {
                  "agency": -2,
                  "tags": {
                    "add": [
                      "career_indecision"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Career in the Arts"
            },
            "description": {
              "en": "You receive an exciting job offer from a prominent art gallery in Lusaka. They've been impressed with your portfolio and want to offer you a position as a studio assistant."
            },
            "image": "/images/09-adulthood_arts-career-offer.webp",
            "imageAlt": "An image representing an art gallery or art career in Zambia.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Accept the Offer"
                },
                "effects": {
                  "agency": 5,
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "arts_career"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Decline the Offer"
                },
                "effects": {}
              }
            ]
          }
        ]
      },
      {
        "title": "Early Childhood",
        "requirements": {
          "age": {
            "gt": 1,
            "lt": 3
          }
        },
        "slides": [
          {
            "title": {
              "en": "Bath Time"
            },
            "description": {
              "en": "You are being bathed."
            },
            "image": "/images/1.5-toddlerhood_happy-bath.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl indoors sitting alone.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "First Memories"
            },
            "description": {
              "en": "You create your first memories with your family."
            },
            "image": "/images/1.5-toddlerhood_older-siblings.webp",
            "imageAlt": "Black-and-white comic book style image of a family eating food together.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Exploring the World"
            },
            "description": {
              "en": "One day, wide-eyed with curiosity, you take your first steps."
            },
            "image": "/images/1.5-toddlerhood_curiosity.webp",
            "imageAlt": "Black-and-white comic book style image of a baby curiously reaching for a colorful object",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Life as a Baby"
            },
            "description": {
              "en": "You are being fed."
            },
            "image": "/images/1.5-toddlerhood_being-fed.webp",
            "imageAlt": "Black-and-white comic book style image of a mother breastfeeding her baby",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue."
                },
                "effects": {
                  "health": 3,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "End Game ",
        "requirements": {
          "age": {
            "gt": 24
          }
        },
        "slides": [
          {
            "title": {
              "en": "Speak About Your Experience"
            },
            "description": {
              "en": "You are giving a motivational talk about your experiences as a young girl, and how you chose a better future for yourself."
            },
            "image": "/images/10-end-game_give-motivational-talk.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "requirements": {
              "tags": {
                "and": [
                  "public_speaking_talent"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Thank your teachers and friends."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "secular"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Thank your family and God."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "pious"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Life in Lusaka"
            },
            "description": {
              "en": "You now work and live in Lusaka, and have enough money to visit home when ever you like."
            },
            "image": "/images/10-end-game_live-in-city.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Visit home to celebrate."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Invite family members to stay with you for a few days and show them a good time."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Life in Mfuwe"
            },
            "description": {
              "en": "You now live in Mfuwe, and have enough money support yourself with extra to spare."
            },
            "image": "/images/10-end-game_live-in-mfuwe.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "trigger",
            "requirements": {
              "tags": {
                "not": [
                  "live_in_the_city"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Invite family to live with you."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "dutiful",
                      "live_in_the_city"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Continue to improve your community using your funds and free time."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "dutiful",
                      "live_in_the_city"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Relative Make a Full Recovery"
            },
            "description": {
              "en": "The older relative you helped care for has made a full a recovery. She is extremely grateful."
            },
            "image": "/images/10-end-game_relative-makes-recovery.webp",
            "imageAlt": "An elderly woman has a made a full recovery, and she is smiling at you.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Tell her it was no problem, and the Lord worked through you."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "pious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Express gratitude that you had the means to take of her."
                },
                "effects": {
                  "relationship": 3,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Wildlife Career"
            },
            "description": {
              "en": "You enjoy a successful career in Wildlife tours and conservation."
            },
            "image": "/images/10-end-game_wildlife-career.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "wildlife_career"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Document your career and post educational material about animals on the internet."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Enjoy a quiet life with the animals and let the resorts handle their own publicity."
                },
                "effects": {
                  "health": 2,
                  "tags": {
                    "add": [
                      "privacy"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Education Career"
            },
            "description": {
              "en": "You are a teacher, and have the opportunity to help young students as you were once helped as a child."
            },
            "image": "/images/10-end-game_education-career.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "education_career"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Teach younger students who are at a critical point in continue school or dropping out."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Teacher secondary school where you can encourage students to pursue their talents and hone their skills."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Engineering Career"
            },
            "description": {
              "en": "You are successful in your engineering career! Though you are one of few women, you are able to make friends and enjoy your work."
            },
            "image": "/images/10-end-game_engineer-career.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "engineering_career"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Create your own start-up with some business minded friends."
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Continue to enjoy your engineering job at the company that hired you directly from university."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "dutiful"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Successful Sports Career"
            },
            "description": {
              "en": "You've achieved your dreams of becoming a professional athlete! Your dedication and hard work have paid off, leading to a rewarding and fulfilling career in sports."
            },
            "image": "/images/10-end-game_sports-career.webp",
            "imageAlt": "Image representing a successful sports career",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "sports_career"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Continue competing."
                },
                "effects": {
                  "agency": 3,
                  "health": 3
                }
              },
              {
                "title": {
                  "en": "Transition to coaching."
                },
                "effects": {
                  "education": 2
                }
              }
            ]
          },
          {
            "title": {
              "en": "Arts Career"
            },
            "description": {
              "en": "Your artistic talent blossoms into a fulfilling career. Your work is celebrated, and you use your platform to inspire others."
            },
            "image": "/images/10-end-game_arts-career.webp",
            "imageAlt": "An image representing a successful arts career",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "arts_career"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Continue to work with ceramics."
                },
                "effects": {
                  "agency": 3,
                  "health": 3
                }
              },
              {
                "title": {
                  "en": "Transition to working with metal."
                },
                "effects": {
                  "education": 2,
                  "agency": 3
                }
              }
            ]
          }
        ]
      }
    ]
  }
];